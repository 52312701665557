import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import axios from "axios";
import Constants from "./Constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const MyProfile = () => {
  const Project_Id = Constants.PROJECTID;
  const [profileData, setProfileData] = useState({
    id: "",
    name:"",
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    phone: "",
    usertype: "",
    addresline1: "",
    addressline2: "",
    city: "",
    state: "",
    ziporpostalcode: "",
    country: "",
    otp: "",
  });
  const [usaStates, setUsaStates] = useState([]);
  const [canadaStates, setCanadaStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);

  const authorid = sessionStorage.getItem("authorid");
  const apiUrl = `https://vanityapi.adef.tech/Customer/Edit?id=${authorid}&authorid=${authorid}&project_id=${Project_Id}`;

  useEffect(() => {
    
    console.log("Fetching profile data from:", apiUrl);

    const fetchProfileData = async () => {
      try {
        const response = await axios.get(apiUrl);
        const data = response.data;
        
        setProfileData({
          id: authorid,
          name: data.name || "",
          firstname: data.firstname || "",
          middlename: data.middlename || "",
          lastname: data.lastname || "",
          email: data.email || "",
          phone: data.phone || "",
          usertype: data.usertype || "",
          addresline1: data.addresline1 || "",
          addressline2: data.addressline2 || "",
          city: data.city || "",
          state: data.state || "",
          ziporpostalcode: data.ziporpostalcode || "",
          country: data.country || "",
          otp: data.otp || "",
        });
        setSelectedType(data.usertype || "");  // assuming usertype is a string
        setSelectedCountry(data.country || ""); // assuming country is a string
        setSelectedState(data.state || "");
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchProfileData();
  }, [apiUrl]);

  useEffect(() => {
    fetch("https://vanityapi.adef.tech/Product/StateList")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setUsaStates(data); // Set the data to the USA states
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };
  const handleDelete = async () => {
    const formData = {
      authorid,
    };
    const data = new URLSearchParams(formData);
  
    try {
      const response = await fetch("https://vanityapi.adef.tech/Customer/DeleteAccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      });
  
      const data1 = await response.json();
  
      if (data1.responseCode === 1) {
        const showSuccessToast = () => {
          toast.success(data1.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              sessionStorage.removeItem("authorid");
              window.location = "/";
            }
          });
        };
        showSuccessToast();
        
      } else {
        const showErrorToast = () => {
          toast.error(data1.responsemessage, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
    } catch (error) {
      console.error("Error while deleting account:", error);
      // Handle error appropriately, maybe show an error toast
    }
  };
  
  const handleSave = async () => {
    setShowOtpField(true);
    try {
      const data = new URLSearchParams({
        id: authorid,
        project_id: Project_Id,
      });
      const updateProfileUrl = `https://vanityapi.adef.tech/Customer/Update`;
      const updateResponse = await fetch(updateProfileUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      });
      const updateData = await updateResponse.json();
      if (updateData.responseCode === 3) {
        toast.success(updateData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        // If the update is successful, proceed with OTP verification and update
        // handleOtpSubmit();
      } else {
        toast.error(updateData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };

  const handleOtpInputChange = (event) => {
    setProfileData((prevData) => ({
      ...prevData,
      otp: event.target.value,
    }));
  };
  const handleStateChange = (e) => {
    const value = e.target.value;
    setSelectedState(value);
    setProfileData((prevData) => ({
      ...prevData,
      state: value,
    }));
  };

  const handleCountryChange = async (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setProfileData((prevData) => ({
      ...prevData,
      country: value,
    }));

    // Fetch states based on the selected country
    try {
      let response;
      let data;

      if (value === "U.S.A") {
        
        response = await fetch("https://vanityapi.adef.tech/Product/StateList");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        setUsaStates(data);
      } else if (value === "Canada") {
        response = await fetch("https://vanityapi.adef.tech/Home/canadastates");
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        data = await response.json();
        
        setCanadaStates(data);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);
    setProfileData((prevData) => ({
      ...prevData,
      usertype: value,
    }));
  };
  const handleOtpSubmit = async () => {
    const data = new URLSearchParams({ ...profileData, otp });
    
    try {
      // Perform API call to update the profile data along with OTP
      const verifyAndUpdateAccountUrl = `https://vanityapi.adef.tech/Customer/VerifyAndUpdateAccount`;
      const response = await fetch(verifyAndUpdateAccountUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: data,
      });
      const responseData = await response.json();

      if (responseData.responseCode === 1) {
        toast.success(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            window.location='/profile?defaultTab=myprofile';
          }
        });

        // setIsEditing(false);
        // setShowOtpField(false);
      } else {
        toast.error(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error updating profile data:", error);
    }
  };

  const renderProfileSettings = () => (
    <div className="my-profile-container">
      <h5>Profile</h5>
      <Row>
        <Col md={6}>
          <div className="userDiv">
            <Row className="">
              <Col md={4}>Name:</Col>
              <Col md={8}>
                {profileData.firstname} {profileData.middlename}{" "}
                {profileData.lastname}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>User Name:</Col>
              <Col md={8}>
                {profileData.name} 
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>Email:</Col>
              <Col md={8}>{profileData.email}</Col>
            </Row>
            <Row className="mt-3">
              <Col md={4}>Mobile:</Col>
              <Col md={8}>{profileData.phone}</Col>
            </Row>
            <Row>
              <Row className="mt-3">
                <Col md={4}>User Type:</Col>
                <Col md={8}>{profileData.usertype}</Col>
              </Row>
            </Row>
            <Row className="mt-3">
              <Col md={4}>Address:</Col>
              <Col md={8}>
                {profileData.addresline1}, {profileData.addressline2}{" "}
                {profileData.city}, {profileData.state}-{" "}
                {profileData.ziporpostalcode}, {profileData.country}
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6}></Col>
      </Row>
      {!isEditing && (
        <Row>
          <Col md={6}>
          <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={handleEdit} className="mt-5">
              Edit Profile
            </Button>
            <Button variant="secondary" onClick={handleDelete} className="mt-5">
              Delete Profile
            </Button>
            </div>
          </Col>
        </Row>
        
      )}
      <Container>
        <Row>
          <Col md={12} className="py-5">
            {isEditing && (
              <Form>
                
                <Form.Group controlId="formUserName" className="columnrow50">
                  <Form.Label>User Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter User Name"
                    name="name"
                    value={profileData.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formName" className="columnrow50">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter First name"
                    name="firstname"
                    value={profileData.firstname}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formName" className="columnrow50">
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Middle name"
                    name="middlename"
                    value={profileData.middlename}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formName" className="columnrow50">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Last name"
                    name="lastname"
                    value={profileData.lastname}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail" className="columnrow50">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    value={profileData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formPhone" className="columnrow50">
                  <Form.Label>Phone</Form.Label>
                  <PhoneInput
                            defaultCountry={"us"} // Default country
                            enableAreaCodes={true}
                            disableCountryCode={true} // Hide country code
                            value={profileData.phone}
                            onChange={(value) =>
                              setProfileData((prevData) => ({
                                ...prevData,
                                phone: value,
                              }))
                            }
                          />
                  {/* <Form.Control
                    type="tel"
                    placeholder="Enter Mobile"
                    name="phone"
                    value={profileData.phone}
                    onChange={handleInputChange}
                  />*/}
                </Form.Group> 
                <Form.Group controlId="formBasicType">
                  <Form.Label>User Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedType}
                    onChange={handleTypeChange}
                  >
                    <option value="" disabled>
                      Select User Type
                    </option>
                    <option value="Business">Business</option>
                    <option value="Personal">Personal</option>
                    <option value="Vendor">Vendor</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  controlId="formAddressLine1"
                  className="columnrow50 mt-2"
                >
                  <Form.Label>Address Line 1</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address line 1"
                    name="addresline1"
                    value={profileData.addresline1}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group
                  controlId="formAddressLine2"
                  className="columnrow50"
                >
                  <Form.Label>Address Line 2</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter address line 2"
                    name="addressline2"
                    value={profileData.addressline2}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formCity" className="columnrow50">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    name="city"
                    value={profileData.city}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBasicCountry">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                    name="country"
                  >
                    <option value="" disabled>
                      Select Country
                    </option>
                    <option value="U.S.A">U.S.A</option>
                    <option value="Canada">Canada</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="formBasicState" className="mt-2">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedState}
                    onChange={handleStateChange}
                    name="state"
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {selectedCountry === "U.S.A" &&
                      usaStates
                        .slice()
                        .sort((a, b) => a.Statename.localeCompare(b.Statename))
                        .map((item, index) => (
                          <option key={index} value={item.Statename}>
                            {item.Statename}
                          </option>
                        ))}
                    {selectedCountry === "Canada" &&
                      canadaStates
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formZipcode" className="columnrow50 mt-2">
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter zipcode"
                    name="ziporpostalcode"
                    value={profileData.ziporpostalcode}
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Button variant="primary" onClick={handleSave}>
                  Save
                </Button>
                {showOtpField && (
                  <Form.Group controlId="formOtp" className="columnrow50 mt-5">
                    <Form.Label>Enter OTP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      name="otp"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                  </Form.Group>
                )}
                {showOtpField && (
                  <Button variant="primary" onClick={handleOtpSubmit}>
                    Submit OTP
                  </Button>
                )}
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );

  return (
    <>
      <div>{renderProfileSettings()}</div>
      <ToastContainer />
    </>
  );
};

export default MyProfile;
