import axios from "axios";
import { useEffect, useState } from "react";

function Notifications() {
    const [notification, setNotification] = useState([]);
    const authorid = sessionStorage.getItem("authorid");

    useEffect(() => {
        // Fetch data from the API
        fetchData();
      }, []); // Empty dependency array means this effect runs once when the component mounts
    
      const fetchData = async () => {
        debugger
        try {
            const authorid = sessionStorage.getItem("authorid");

          const response = await axios.get(`https://vanityapi.adef.tech/Customer/GetNotificationsByCustomerId?customerid=${authorid}`)
          .then(response => {
            setNotification(response.data);  
        });
            
      
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      function renderContentWithLineBreaks(content) {
        return content.replace(/\n/g, "<br/>");
      }
  return (
    <>
      {notification.length>0?(
        notification.map((rowData, index) => (
            <div className="faq-item" key={index}>
              <p dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(rowData.notification) }}></p>
              <small>{formatTimestamp(rowData.createdon)}</small>
            </div>
          ))
      ):(
        <div className="faq-item">
        <p className="m-0">No new notifications for you. Enjoy your day!</p>
        </div>
      )
      }
    </>
  );
}
function formatTimestamp(timestampdate){
    const timestamp = parseInt(timestampdate.replace(/\/Date\((-?\d+)\)\//, '$1'));

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth()+1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours}:${minutes}:${seconds}`;

}
export default Notifications;
