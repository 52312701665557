import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import CartButton from "./CartButton";
import SideNav from "./SideNav";
import "./PatternNumbers.css";
import Sorting from "./Sorting";
import FilterSidebar from "./FilterSidebar";

function PatternNumbers() {
  const { patternId } = useParams();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("lowToHigh"); // Default sorting order
  const [selectedState, setSelectedState] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("");
  const [selectedAreacode, setSelectedAreacode] = useState(""); // Add this state
  const [activeKey, setActiveKey] = useState(""); 
  const [initialLoad, setInitialLoad] = useState(false);
  useEffect(() => {
    if (!initialLoad) {
      // Fetch data only on initial page load
      fetchData();
      setInitialLoad(true);
    }
  }, [initialLoad]);
  useEffect(() => {
    if (initialLoad) {
      // Apply filters when selectedState, selectedPattern, or selectedAreacode change
      applyFilters(selectedState, selectedPattern, selectedAreacode);
    }
  }, [selectedState, selectedPattern, selectedAreacode, initialLoad]);
  useEffect(() => {
    // Call handleSelectState directly when selectedState changes
    debugger
    handleSelectState(selectedState);
  }, [selectedState]);


  // useEffect(() => {
  //   // This useEffect will run on component mount and whenever selectedState changes
  //   handleSelectState(selectedState); // Explicitly call handleSelectState
  // }, [selectedState, selectedPattern, selectedAreacode]); // Include selectedState in the dependency array

  // useEffect(() => {
  //   fetchData();
  //   setSelectedPattern(patternId);
  // }, [ patternId]);
  
  const fetchData = () => {
    
    let apiUrl;

    if (selectedState && patternId && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedState}&pattern=${patternId}&areacode=${selectedAreacode}`;
    } else if (selectedState && patternId) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedState}&pattern=${patternId}`;
    } else if (selectedState && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedState}&areacode=${selectedAreacode}`;
    } else if (patternId && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${patternId}&areacode=${selectedAreacode}`;
    } else if (selectedState) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedState}`;
    } else if (patternId) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${patternId}`;
    } else if (selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?areacode=${selectedAreacode}`;
    } else {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting`;
    }

    axios
      .get(apiUrl)
      .then((response) => {
        setPhoneNumbers([]);
        if (response.data.length >= 1) {
          setPhoneNumbers(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching phone numbers:", error);
        setLoading(false);
      });
  };
  if (phoneNumbers.length === 0) {
    var nonumber = <p>No phone numbers available for {patternId}.</p>;
  }
  const handleSelectState = (stateName) => {
    setSelectedState(stateName);
    setSelectedAreacode("");
    debugger
    // applyFilters(stateName, selectedPattern, selectedAreacode);
    setActiveKey("0");
  };

  const handleSelectPattern = (patternId) => {
    setSelectedPattern(patternId);
    // applyFilters(selectedState, patternId, selectedAreacode);
    setActiveKey("1");
  };

  const handleSelectAreacode = (selectedAreacode) => {
    setSelectedAreacode(selectedAreacode);
    // applyFilters(selectedState, selectedPattern, selectedAreacode);
    setActiveKey("2");
  };
  // Sort phone numbers based on the selected order
  const sortedPhoneNumbers = [...phoneNumbers].sort((a, b) => {
    const priceA = a.rate;
    const priceB = b.rate;

    return sortBy === "lowToHigh" ? priceA - priceB : priceB - priceA;
  });
  const applyFilters = (selectedStatep, selectedPatternp, selectedAreacodep) => {
    // You can use the selectedState and selectedPattern to filter the data
    // Update the API endpoint accordingly
    setSelectedState(selectedStatep);
  setSelectedAreacode(selectedAreacodep);
    setSelectedPattern(selectedPatternp|| patternId);
    // Use the existing patternId if it is available
    var patternToUse ="";
    if(selectedState !== "" && selectedPatternp == ""){
      patternToUse= "";
    } else {
      patternToUse = selectedPatternp || patternId;
    }
  debugger
    setPhoneNumbers([]);
    axios
      .get(
        `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedState}&pattern=${patternToUse}&areacode=${selectedAreacode}`
      )
      .then((response) => {
        if (response.data.length >= 1) {
          setPhoneNumbers(response.data);
        }
      })
      .catch((error) => {
        console.error("Error applying filters:", error);
      });
  };
  
  // Check if there are no phone numbers
  if (loading) {
    return <p>Loading...</p>;
  }
  if (sortedPhoneNumbers.length === 0) {
    nonumber = <p>No phone numbers available for {selectedPattern}{" "}
    {selectedState && <span> with {selectedState}</span>}
    {selectedAreacode && <span> and {selectedAreacode}</span>}</p>;
  }
 
  const phoneNumberList = sortedPhoneNumbers.map((item) => (
    <Col md={12} key={item.Id}>
      <div className="box">
        <Row>
          <Col md={6}>
            <p className="box-number text-start ">{item.name}</p>
          </Col>
          <Col md={6}>
            <p className="box-price text-end m-0">
              ${parseFloat(item.rate).toFixed(2)}(
              <span className="box-discount text-end">
                {item.discount}% Off 
              </span>
              )
            </p>
            <p className="box-price text-end m-0">
                          <span className="box-discount text-end">
                            Save $
                            {(
                              parseFloat(item.rate) - parseFloat(item.price)
                            ).toFixed(2)}
                          </span>
                        </p>
          </Col>
          <Col md={6}>
            <button className="location-button mb-3">
              <FaMapMarkerAlt /> {item.state}
            </button>
            <p className="box-patterns m-0">Pattern: {item.pattern}</p>
<p className="activatetext">Activate in 7-10 days</p>
          </Col>
          <Col md={6} className="mt-auto">

          {item.stock > 0 ?  (
              <>
                <p className="statustext">{item.status}</p>
<CartButton  
                  productId={item.id}
                  className="buybutton"
                ></CartButton>
                {item.rate > 5000 && (
                  <>
                    <p className="offertext">You can Make an Offer</p>
                  </>
                )}
              </>
            ) :(
              <p className="sold-out-text text-end">Sold Out</p>
            )}
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
    </Col>
  ));

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col md={4}></Col>
        <Col md={12} className="text-center">
          <h2>Phone Numbers for Pattern : {selectedPattern}</h2>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Container className="pb-5">
        <Row className="mt-3">
          <Col md={4}>
          <FilterSidebar applyFilters={applyFilters} selectedState={selectedState}
  selectedPattern={selectedPattern} />
          </Col>
          <Col className={8}>
            <Row>
              <Sorting setSortBy={setSortBy} />
              {phoneNumberList}
              {nonumber}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default PatternNumbers;
