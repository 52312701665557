import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import CartButton from "./CartButton";
import "./StateNumber.css";
import SideNav from "./SideNav";
import Sorting from "./Sorting";
import FilterSidebar from "./FilterSidebar";


function Statenumber() {
  const { stateName } = useParams();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("lowToHigh"); // Default sorting order
  const [selectedStatename, setSelectedStatename] = useState("");
  const [selectedPatternname, setSelectedPatternname] = useState("");
  const [selectedAreacode, setSelectedAreacode] = useState(""); 
  const [selectedArealist, setSelectedArealist] = useState(""); 
  const [activeKey, setActiveKey] = useState("");
  const [initialLoad, setInitialLoad] = useState(false);
  const [allState, setallState] = useState("");

  useEffect(() => {
    if (!initialLoad) {
      // Fetch data only on initial page load
      fetchData();
      setInitialLoad(true);
    }
  }, [initialLoad]);
  useEffect(() => {
    if (initialLoad) {
      // Apply filters when selectedState, selectedPattern, or selectedAreacode change
      applyFilters(selectedStatename, selectedPatternname, selectedAreacode);
    }
  }, [selectedStatename, selectedPatternname, selectedAreacode, initialLoad]);

  useEffect(() => {
    if (!initialLoad) {
      fetchData();
      setSelectedStatename(stateName);
      debugger
      fetchAreaCodes(stateName);
    }
  }, [initialLoad]);
  useEffect(() => {
    // Call handleSelectState directly when selectedState changes
    // handleSelectState(selectedStatename);
  }, [selectedStatename]);


  const fetchData = () => {
    // setSelectedAreacode("");
    let apiUrl;

    if (stateName && selectedPatternname && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}&pattern=${selectedPatternname}&areacode=${selectedAreacode}`;
    } else if (stateName && selectedPatternname) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}&pattern=${selectedPatternname}`;
    } else if (stateName && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}&areacode=${selectedAreacode}`;
    } else if (selectedPatternname && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${selectedPatternname}&areacode=${selectedAreacode}`;
    } else if (stateName) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}`;
    } else if (selectedPatternname) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${selectedPatternname}`;
    } else if (selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?areacode=${selectedAreacode}`;
    } else {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting`;
    }
    axios
      .get(apiUrl)
      .then((response) => {
        setPhoneNumbers([]);
        if (response.data.length >= 1) {
          setPhoneNumbers(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching phone numbers:", error);
        setLoading(false);
      });
  };
  const fetchAreaCodes = (stateName) => {
    setSelectedStatename(stateName);
    debugger
    axios
      .get(`https://vanityapi.adef.tech/Product/AreacodeBasedonState?state=${stateName}`)
      .then((response) => {
        setSelectedArealist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching area codes:", error);
      });
  };
  // Sort phone numbers based on the selected order
  const sortedPhoneNumbers = [...phoneNumbers].sort((a, b) => {
    const priceA = a.rate;
    const priceB = b.rate;

    return sortBy === "lowToHigh" ? priceA - priceB : priceB - priceA;
  });
  const applyFilters = (selectedStatenamep, selectedPatternnamep, selectedAreacodep) => {
    setSelectedStatename(selectedStatenamep);
    setSelectedAreacode(selectedAreacodep);
  setSelectedPatternname(selectedPatternnamep);
    setPhoneNumbers([]);
    debugger;
    var stateToUse = "";
    if (selectedPatternnamep !== "" && selectedStatenamep == ""){
      stateToUse = "" ;
    } else {
      stateToUse  = selectedStatenamep || stateName ;
    }
   
   
    const apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateToUse}&pattern=${selectedPatternname}&areacode=${selectedAreacode}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.length >= 1) {
          setPhoneNumbers(response.data);
        }
      })
      .catch((error) => {
        console.error("Error applying filters:", error);
      });
  };

  const handleSelectState = (stateName) => {
    setSelectedStatename(stateName);
    setSelectedAreacode("");
    fetchAreaCodes(stateName);
    debugger
    // applyFilters(stateName, selectedPatternname, selectedAreacode);
    setActiveKey("0");
  };

  const handleSelectPattern = (patternId) => {
    setSelectedPatternname(patternId);
    // applyFilters(selectedStatename, patternId, selectedAreacode);
    setActiveKey("1");
  };

  const handleSelectAreacode = (selectedAreacode) => {
    setSelectedAreacode(selectedAreacode);
    // applyFilters(selectedStatename, selectedPatternname, selectedAreacode);
    setActiveKey("2");
  };

  // Check if there are no phone numbers
  if (loading) {
    return <p>Loading...</p>;
  }
  if (sortedPhoneNumbers.length === 0) {
    var nonumber = <p>
      No phone numbers available for {selectedStatename}
      {selectedPatternname && <span> with {selectedPatternname}</span>}.

    </p>;
  }

  if (phoneNumbers.length === 0) {
    nonumber = <p>No phone numbers available for {selectedStatename}
           {selectedPatternname && <span> with {selectedPatternname}</span>}
           {selectedAreacode && <span> with {selectedAreacode}</span>}.
</p>;
  }
  var phoneNumberList = sortedPhoneNumbers.map((item) => (
    // ... (existing code for rendering phone numbers)
    <Col md={12} key={item.Id}>
      <div className="box">
        <Row>
          <Col md={6}>
            <p className="box-number text-start ">{item.name}</p>
          </Col>
          <Col md={6}>
            <p className="box-price text-end m-0">
              ${parseFloat(item.rate).toFixed(2)}(
              <span className="box-discount text-end">
                {item.discount}% Off
              </span>
              )
            </p>
            <p className="box-price text-end m-0">
              (
              <span className="box-discount text-end">
                Save ${item.rate}-{item.price}
              </span>
              )
            </p>
          </Col>
          <Col md={6}>
            <button className="location-button mb-3">
              <FaMapMarkerAlt /> {item.state}
            </button>
            <p className="box-patterns m-0">Pattern: {item.pattern}</p>
            <p className="activatetext">Activate in 7-10 days</p>
          </Col>
          <Col md={6} className="mt-auto">
            {item.stock > 0 && (
              <>
                <p className="statustext">{item.status}</p>
                <CartButton
                  productId={item.id}
                  className="buybutton"
                ></CartButton>
                {item.rate > 5000 && (
                  <>
                    <p className="offertext">You can Make an Offer</p>
                  </>
                )}
              </>
            )}
            {item.stock === 0 && (
              <p className="sold-out-text text-end">Sold Out</p>
            )}
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
    </Col>
  ));

  return (
    <Container fluid>
      <Row className="mt-5">
        <Col md={4}></Col>
        <Col md={12} className="text-center">
          {selectedStatename ? (
            <h2>Phone Numbers for {selectedStatename}</h2>
          ) : (
            <h2>Phone Numbers for {stateName}</h2>
          )}
        </Col>
        <Col md={4}></Col>
      </Row>
      <Container className="pb-5">
        <Row className="mt-3">
          <Col md={4}>
            <FilterSidebar applyFilters={applyFilters} selectedState={selectedStatename} selectedPattern={selectedPatternname}/>
          </Col>
          <Col className={8}>
            <Row>
              <Sorting setSortBy={setSortBy} />
              {sortedPhoneNumbers.map((item) => (
                <Col md={12} key={item.Id}>
                  <div className="box">
                    <Row>
                      <Col md={6}>
                        <p className="box-number text-start ">{item.name}</p>
                      </Col>
                      <Col md={6}>
                        <p className="box-price text-end m-0">
                          ${parseFloat(item.rate).toFixed(2)}(
                          <span className="box-discount text-end">
                            {item.discount}% Off
                          </span>
                          )
                        </p>
                        <p className="box-price text-end m-0">
                          <span className="box-discount text-end">
                            Save $
                            {(
                              parseFloat(item.rate) - parseFloat(item.price)
                            ).toFixed(2)}
                          </span>
                        </p>
                      </Col>
                      <Col md={6}>
                        <button className="location-button mb-3">
                          <FaMapMarkerAlt /> {item.state}
                        </button>
                        <p className="box-patterns m-0">
                          Pattern: {item.pattern}
                        </p>
                        <p className="activatetext">Activate in 7-10 days</p>
                      </Col>
                      <Col md={6} className="mt-auto">
                        {item.stock > 0 && (
                          <>
                            <p className="statustext">{item.status}</p>
                            <CartButton
                              productId={item.id}
                              className="buybutton"
                            ></CartButton>
                            {item.rate > 5000 && (
                              <>
                                <p className="offertext">
                                  You can Make an Offer
                                </p>
                              </>
                            )}
                          </>
                        )}

                        {item.stock === 0 && (
                          <p className="sold-out-text text-end">Sold Out</p>
                        )}
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </div>
                </Col>
              ))}

              {!loading &&
                (phoneNumbers.length === 0 || phoneNumbers === null) && (
                  <p> No phone numbers available for{" "}
                  {selectedStatename ? selectedStatename : stateName}.
                  {selectedPatternname && <span> with {selectedPatternname}</span>}.
                  </p>       
                )}
            </Row>
          </Col>
        </Row>


      </Container>
    </Container>
  );
}

export default Statenumber;