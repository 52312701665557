import React, { useEffect, useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailParam = searchParams.get('email');
    setEmail(emailParam || '');
  }, [location.search]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const url = "https://vanityapi.adef.tech/Customer/ResetPasswordAfterOTP";
    const data = new URLSearchParams({
      newPassword,
      email,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });

      const responseData = await response.json();
      if (responseData.responseCode === 1) {
        const showSuccessToast = () => {
          toast.success(responseData.responsemessage, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            onClose: () => { window.location= '/' }
          });
        };
        showSuccessToast();
      } else {
        const showErrorToast = () => {
          toast.error(responseData.responsemessage, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        };
        showErrorToast();
      }
    } catch (error) {
      console.error("Fetch error:", error);
    }
  };

  return (
    <>
      <Container className="py-5">
        <Row>
            <Col md={{span:6, offset:3}}>
                <h3>Reset Your Password</h3>
            <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formNewPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit">
            Reset Password
          </Button>
        </Form>
            </Col>
        </Row>
        
      </Container>
      <ToastContainer />
    </>
  );
}

export default ResetPassword;
