import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import CartButton from "./CartButton";
import SideNav from "./SideNav";
import "./PatternNumbers.css";
import Sorting from "./Sorting";
import FilterSidebar from "./FilterSidebar";

function Allnumbers() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedState = queryParams.get("state");
  const selectedPattern = queryParams.get("pattern");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState("lowToHigh"); // Default sorting order
  const [selectedStatename, setSelectedStatename] = useState("");
  const [selectedPatternname, setSelectedPatternname] = useState("");
  const [selectedAreacode, setSelectedAreacode] = useState(""); 
  const [initialLoad, setInitialLoad] = useState(false);
  useEffect(() => {
    if (!initialLoad) {
      // Fetch data only on initial page load
      fetchData();
      setInitialLoad(true);
    }
  }, [initialLoad]);
  const fetchData = () => {
    
    // let apiUrl;
    let apiUrl = "https://vanityapi.adef.tech/Home/phonenumberslisting";

  // Construct API URL based on selected state and pattern
  if (selectedState || selectedPattern) {
    apiUrl += "?";
    if (selectedState) {
      apiUrl += `state=${selectedState}`;
      if (selectedPattern) {
        apiUrl += `&pattern=${selectedPattern}`;
      }
    } else if (selectedPattern) {
      apiUrl += `pattern=${selectedPattern}`;
    }
  } else if (selectedStatename && selectedPatternname && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedStatename}&pattern=${selectedPatternname}&areacode=${selectedAreacode}`;
    } else if (selectedStatename && selectedPatternname) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedStatename}&pattern=${selectedPatternname}`;
    } else if (selectedStatename && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedStatename}&areacode=${selectedAreacode}`;
    } else if (selectedPatternname && selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${selectedPatternname}&areacode=${selectedAreacode}`;
    } else if (selectedStatename) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedStatename}`;
    } else if (selectedPatternname) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${selectedPatternname}`;
    } else if (selectedAreacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?areacode=${selectedAreacode}`;
    } else {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting`;
    }

    axios
      .get(apiUrl)
      .then((response) => {
        setPhoneNumbers([]);
        if (response.data.length >= 1) {
          setSelectedStatename(selectedStatename);
          setSelectedAreacode(selectedAreacode);
          setSelectedPatternname(selectedPatternname);
          setPhoneNumbers(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching phone numbers:", error);
        setLoading(false);
      });
  };
  const applyFilters = (selectedStatenamep, selectedPatternnamep, selectedAreacodep) => {
    setSelectedStatename(selectedStatenamep);
    setSelectedAreacode(selectedAreacodep);
    setSelectedPatternname(selectedPatternnamep);
    
    var stateToUse = "";
    if (selectedPatternnamep !== "" && selectedStatenamep == ""){
      stateToUse = "" ;
    } else {
      stateToUse  = selectedStatenamep  ;
    }
   
    var patternToUse ="";
    if(selectedStatenamep !== "" && selectedPatternnamep == ""){
      patternToUse= "";
    } else if(selectedPatternnamep == "" || selectedPatternnamep == null){
      patternToUse="";
    }
    else {
      patternToUse = selectedPatternnamep ;
    }

    var areacodeToUse ="";
    if(selectedAreacodep !== ""){
      areacodeToUse= selectedAreacodep;
    } else {
      areacodeToUse = "";
    } 

    setPhoneNumbers([]);
    const apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateToUse}&pattern=${patternToUse}&areacode=${areacodeToUse}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data.length >= 1) {
          debugger
          setPhoneNumbers(response.data);
        }
      })
      .catch((error) => {
        console.error("Error applying filters:", error);
      });
  };
  if (phoneNumbers.length === 0) {
    debugger
    var nonumber = <p>No phone numbers available for {selectedPatternname}{" "}
    {selectedStatename ? <span> with {selectedStatename}</span> : selectedState}
    {selectedAreacode && <span> and {selectedAreacode}</span>}</p>;
  }
  // Sort phone numbers based on the selected order
  const sortedPhoneNumbers = [...phoneNumbers].sort((a, b) => {
    const priceA = a.rate;
    const priceB = b.rate;
    debugger
    return sortBy === "lowToHigh" ? priceA - priceB : priceB - priceA;
  });
  // Check if there are no phone numbers
  if (loading) {
    return <p>Loading...</p>;
  }
  if (sortedPhoneNumbers.length === 0) {
    nonumber = <p>No phone numbers available for {selectedPatternname ? <span> with {selectedPatternname}</span> : selectedPattern}    {" "}
    {selectedStatename ? <span> with {selectedStatename}</span> : selectedState}
    {selectedAreacode && <span> and {selectedAreacode}</span>}</p>;
  }

  const phoneNumberList = sortedPhoneNumbers.map((item) => (
    <Col md={12} key={item.Id}>
      <div className="box">
        <Row>
          <Col md={6}>
            <p className="box-number text-start ">{item.name}</p>
          </Col>
          <Col md={6}>
            <p className="box-price text-end m-0">
              ${parseFloat(item.rate).toFixed(2)}(
              <span className="box-discount text-end">
                {item.discount}% Off 
              </span>
              )
            </p>
            <p className="box-price text-end m-0">
                          <span className="box-discount text-end">
                            Save $
                            {(
                              parseFloat(item.rate) - parseFloat(item.price)
                            ).toFixed(2)}
                          </span>
                        </p>
          </Col>
          <Col md={6}>
            <button className="location-button mb-3">
              <FaMapMarkerAlt /> {item.state}
            </button>
            <p className="box-patterns m-0">Pattern: {item.pattern}</p>
            <p className="activatetext">Activate in 7-10 days</p>
          </Col>
          <Col md={6} className="mt-auto">

          {item.stock > 0 ? (
              <>
                <CartButton  
                  productId={item.id}
                  className="buybutton"
                ></CartButton>
                {item.rate > 5000 && (
                  <>
                    <p className="offertext">You can Make an Offer</p>
                  </>
                )}
              </>
            ) : (
              <p className="sold-out-text text-end">Sold Out</p>
            )}
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
    </Col>
  ));
  var stateToSend = "";
    if (selectedStatename == ""){
      stateToSend = selectedState ;
    } else {
      stateToSend  = selectedStatename ;
    }
    var patternToSend = "";
    if (selectedPatternname == ""){
      patternToSend = selectedPattern ;
    } else {
      patternToSend  = selectedPatternname ;
    }
    var areacodeToSend = "";
    if (selectedAreacode == ""){
      areacodeToSend = "" ;
    } else {
      areacodeToSend  = selectedAreacode ;
    }
  return (
    <Container fluid>
      <Row className="mt-5">
        {/* <Col md={4}></Col> */}
        <Col className="text-center">
          <h2>Phone Numbers
          {selectedStatename && selectedPatternname && selectedAreacode ? 
            <span>: {selectedStatename} & {selectedPatternname} ({selectedAreacode})</span> : 
            selectedStatename && selectedPatternname ? 
            <span>: {selectedStatename} & {selectedPatternname}</span> :
            selectedStatename && selectedAreacode ?
            <span>: {selectedStatename} ({selectedAreacode})</span> :
            selectedPatternname && selectedAreacode ?
            <span>: {selectedPatternname} ({selectedAreacode})</span> :
            selectedStatename ?
            <span>: {selectedStatename}</span> :
            selectedPatternname ?
            <span>: {selectedPatternname}</span> :
            selectedAreacode ?
            <span>: {selectedAreacode}</span> :
            selectedState ?
            <span>: { selectedState}</span> :
            selectedPattern ?
            <span>: { selectedPattern}</span>:
            null
          }

          </h2>
        </Col>
        {/* <Col md={4}></Col> */}
      </Row>
      <Container className="pb-5">
        <Row className="mt-3">
          <Col md={4}>
          <FilterSidebar applyFilters={applyFilters} selectedState={stateToSend} selectedPattern={patternToSend} selectAreacode={areacodeToSend}/>

          </Col>
          <Col className={8}>
            <Row>
              <Sorting setSortBy={setSortBy} />
              {phoneNumberList}
              {nonumber}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Allnumbers;