import React from 'react';
import { Container } from 'react-bootstrap';
import "./Customnumbers.css";

const CustomNumbers = () => {
    return (
        <Container fluid className='customnumbers p-2'>
            <Container>
            <section className="table-section mb-5">
                <h1>Custom Order</h1>
                <p>1) If you are looking for the Fancy/Vanity Phone Number especially in USA
                    We are suggesting you to please visit our website.
                </p>
                <h2>Features:</h2>
                <p>
                    You can request the desired Fancy/VIP/Vanity mobile number.
                    You can send requests for up to 10 choices of series with 7 digits.
                    The VIP Mobile Number will be delivered online or by post.
                </p>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Sl.No</th>
                            <th>Format/Pattern</th>
                            <th>Number Pattern</th>
                            <th>Example</th>
                            <th>Actual Format</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Repeater</td>
                            <td>***-XXX-XXXX</td>
                            <td>234-999-999</td>
                            <td>***-999-9999</td>
                            <td>Diamond</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Thousand</td>
                            <td>***-ZYY-ZYYY</td>
                            <td>234-700-700</td>
                            <td>***-700-7000</td>
                            <td>Gold</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Ascending</td>
                            <td>***-*12-3456</td>
                            <td>234-412-3456</td>
                            <td>***-*12-3456</td>
                            <td>Diamond</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Other Choice</td>
                            <td>***-XXX-XXXX</td>
                            <td>234-999-8888</td>
                            <td>***-999-8888</td>
                            <td>Platinum</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Other Choice</td>
                            <td>***-***-YYYY</td>
                            <td>234-274-5555</td>
                            <td>***-***-5555</td>
                            <td>Gold</td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Double Ascending</td>
                            <td>***-*XX-ZZYY</td>
                            <td>234-411-2233</td>
                            <td>***-*11-2233</td>
                            <td>Gold</td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Vanity Choice</td>
                            <td>***-*I fix it</td>
                            <td>234-6 I FIX IT</td>
                            <td>***-*43-4948</td>
                            <td>All</td>
                        </tr>
                        <tr>
                            <td>8</td>
                            <td>Vanity Choice</td>
                            <td>***-*We-rent</td>
                            <td>234-7WE-RENT</td>
                            <td>***-*93-7368</td>
                            <td>All</td>
                        </tr>
                        <tr>
                            <td>9</td>
                            <td>Vanity Choice</td>
                            <td>***-*we Do It</td>
                            <td>234-6WE DO IT</td>
                            <td>***-*93-3648</td>
                            <td>All</td>
                        </tr>
                        <tr>
                            <td>10</td>
                            <td>Special Choice</td>
                            <td>XYY-YYY-YYYY</td>
                            <td>977-777-7777</td>
                            <td>*YY-YYY-YYYY</td>
                            <td>Diamond</td>
                        </tr>
                        
                    </tbody>
                </table>
            </section>





            <section className="content-section">
                {/* Static content goes here */}
                <p>
                    2) Enter your choice of VIP Number Series of last 7 or 4 or 3 Digits. Please send 4 choices of pattern then we will easily find numbers for you, for example If you are wishing to get.
                    {/* ... Remaining static content ... */}
                </p>
                <p>
                    Other Examples: XYXY or XYYY or 0XXX / XY00 / 00XY / XXXX / X000 / XX00 / XXX0 / X0X0 / 000X / 00XX / 0X00 /XYXYXY /X00-X000 / XXYY XXXY /X00X / XXX-YYYY
                    Note: "X" is one number in Phone Number, "Y" is Another number other than X in the Same Phone Number.   ” Z” is different number not same as X and Y.   "*" Is Any number between 0 to 9.
                    a) Example: XXX-YYYY=222-4444. b) XXY-XXXY=332-3332.

                </p>

                <p>
                    3) You have to give us at least two choices and with the minimum of 4 digits series.
                </p>

                <p>
                    4)Fill You Name, Email-Id and Click on Send.
                </p>
                <p>
                    5) You will Receive an Auto Mated mail to your mail-id which you are entered.
                </p>
                <p>
                    6) As per your request we will search for the series as per your choices.
                </p>
                <p>
                    7) Once we find the series VIP Mobile Number, We will send you the Mobile Numbers to your email id or mobile number.
                </p>
                <p>
                    8) You must Select the desired Mobile Number and revert us.
                </p>
                <p>
                    9) We will Contact you and Deliver the VIP Mobile Number to your Doorstep Or Online.
                </p>
                <p>
                    10) Prices of VIP Mobile Numbers.
                </p>
                <p>
                    11) We are suggesting you search with multiple Area codes to get the VIP Mobile Number within the Best Price. But below are the Consequences.
                </p>
                <p>
                    12) Finding the Desired Series is difficult and Expensive if you are searching with the only one Area Code, You have to select the VIP Mobile Number from Different Area Codes then you will get it for cheaper and the best.
                </p>
                <p>
                    13)We can block the number not to sell for anyone else, once you made the Payment.
                </p>
                <p>
                    Searching for the desired Number from multiple Area codes, Its easy and inexpensive. It’s Just a suggestion to check the choice of fancy/VIP mobile number with us.
                </p>
                <p>
                    You can check our website and get the desired Fancy Phone Numbers
                </p>
            </section>
            </Container>
        </Container>
    );
};

export default CustomNumbers;
