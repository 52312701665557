import React, { useEffect, useState } from "react";
import "./FAQS.css"; // Import your CSS file
import { Button, Container } from "react-bootstrap";
import Local from "./Local";
import TollFreeNumbers from "./TollFreeNumbers";
import ProcessFAQ from "./ProcessFAQ";
import { Link } from "react-router-dom";

const FAQs = () => {
  const scrollToContainer = (containerId) => {
    const container = document.getElementById(containerId);

    if (container) {
      container.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [generalData, setGeneralData] = useState([]);
  const [localData, setLocalData] = useState([]);
  const [tollFreeData, setTollFreeData] = useState([]);
  const [portingOutData, setPortingOutData] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    fetchData();
  }, []); // Empty dependency array means this effect runs once when the component mounts

  const fetchData = async () => {
    try {
      // Make API request
      const response = await fetch("https://vanityapi.adef.tech/Admin/ListFAQs");
      const data = await response.json();
  
      // Categorize data based on the category property
      const generalItems = [];
      const localItems = [];
      const tollFreeItems = [];
      const portingOutItems = [];
  
      data.forEach((item) => {
        if (item.category === "General") {
          generalItems.push(item);
        } else if (item.category === "Local") {
          localItems.push(item);
        } else if (item.category === "Toll-Free") {
          tollFreeItems.push(item);
        } else if (item.category === "Porting Out") {
          portingOutItems.push(item);
        }
      });
  
      // Update state with the categorized data
      setGeneralData(generalItems);
      setLocalData(localItems);
      setTollFreeData(tollFreeItems);
      setPortingOutData(portingOutItems);
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const renderContentWithLineBreaks = (content) => {
    return content.replace(/\\n/g, '<br>');
  };
  return (
    <section className="faqsection">
      <Container className="py-4 text-center">
        <Button
          className="faqbtn mx-2"
          onClick={() => scrollToContainer("general")}
        >
          General
        </Button>
        <Button
          className="faqbtn mx-2"
          onClick={() => scrollToContainer("local")}
        >
          Local{" "}
        </Button>
        <Button
          className="faqbtn mx-2"
          onClick={() => scrollToContainer("tollfree")}
        >
          Toll-Free
        </Button>
        <Button
          className="faqbtn mx-2"
          onClick={() => scrollToContainer("porting")}
        >
          Porting Out
        </Button>
      </Container>
      <Container fluid className="faqs-container p-2" id="general">
        <div className="faq-container mt-4">
        {generalData.map((rowData, index) => (
          <div className="faq-item" key={index}>
            <h3>{rowData.question}</h3>
            <p dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(rowData.answer) }}></p>
            </div>
            ))}
        </div>
      </Container>
      <Container id="local">
        <div className="faq-container mt-4">
          <h2 className="faq-container mt-4">
            New Local Numbers
          </h2>
          {localData.map((rowData, index) => (
          <div className="faq-item" key={index}>
            <h3>{rowData.question}</h3>
            <p dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(rowData.answer) }}></p>
            </div>
            ))}
        </div>
      </Container>
      <Container fluid id="tollfree" className="faqs-container p-2">
      <div className="faq-container mt-4">
        <h2 className="faq-container mt-4">Toll-Free Numbers</h2>
        {tollFreeData.map((rowData, index) => (
          <div className="faq-item" key={index}>
            <h3>{rowData.question}</h3>
            <p dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(rowData.answer) }}></p>
            </div>
            ))}
        </div>
      </Container>
      <Container id="porting">
      <div className="faq-container mt-4">
      <h2 className="faq-container mt-4">Porting Away from NumberKarma</h2>
      {portingOutData.map((rowData, index) => (
          <div className="faq-item" key={index}>
            <h3>{rowData.question}</h3>
            <p dangerouslySetInnerHTML={{ __html: renderContentWithLineBreaks(rowData.answer) }}></p>
            </div>
            ))}
            </div>
      </Container>
    </section>
  );
};

export default FAQs;
