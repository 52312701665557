import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import CartButton from "./CartButton";
import Sorting from "./Sorting";
import FilterSidebar from "./FilterSidebar";

function Showall() {
  const { stateName, pattern } = useParams();
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [activeKey, setActiveKey] = useState("0");
  const [areacode, setAreacode] = useState(""); 
  const [loading, setLoading] = useState(true);
  const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState([]);
  const [sortBy, setSortBy] = useState("lowToHigh"); // Default sorting order
  const [selectedState, setSelectedState] = useState("");
  const [selectedPattern, setSelectedPattern] = useState("");
  const [selectedAreacode, setSelectedAreacode] = useState("");

  useEffect(() => {
    fetchData();
    setSelectedState(selectedState || "");
    setSelectedPattern(selectedPattern || "");
    setSelectedAreacode(selectedAreacode || "");
    setAreacode(areacode || ""); // Add this line
  }, []);

  const fetchData = () => {
    
    let apiUrl;
    if (stateName && pattern && areacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}&pattern=${pattern}&areacode=${selectedAreacode}`;
    } else if (stateName && pattern) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}&pattern=${pattern}`;
    } else if (stateName && areacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}&areacode=${selectedAreacode}`;
    } else if (pattern && areacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${pattern}&areacode=${selectedAreacode}`;
    } else if (stateName) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${stateName}`;
    } else if (pattern) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?pattern=${pattern}`;
    } else if (areacode) {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting?areacode=${selectedAreacode}`;
    } else {
      apiUrl = `https://vanityapi.adef.tech/Home/phonenumberslisting`;
    }

    axios
      .get(apiUrl)
      .then((response) => {
        setPhoneNumbers([]);
        if (response.data.length >= 1) {
          setPhoneNumbers(response.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching phone numbers:", error);
        setLoading(false);
      });
  };
  const applyFilters = (selectedState, selectedPattern, selectedAreacode) => {
    // You can use the selectedState and selectedPattern to filter the data
    // Update the API endpoint accordingly
    debugger;
    setSelectedState(selectedState);
    setSelectedPattern(selectedPattern);
    setSelectedAreacode(selectedAreacode);
    setPhoneNumbers([]);
    debugger
    axios
      .get(
        `https://vanityapi.adef.tech/Home/phonenumberslisting?state=${selectedState}&pattern=${selectedPattern}&areacode=${selectedAreacode}`
      )
      .then((response) => {
        
        if(response.data.length >=1 ){
        setPhoneNumbers(response.data);
        }
      })
      .catch((error) => {
        console.error("Error applying filters:", error);
      });
  };

  // Check if there are no phone numbers
  if (loading) {
    return <p>Loading...</p>;
  }

  // Display message when there are no phone numbers
  if (filteredPhoneNumbers.length === 0) {
    var nonumber = <p>No phone numbers available.</p>;
  }
  var phoneNumberList = filteredPhoneNumbers.map((item) => (
    <Col md={12} key={item.Id}>
      <div className="box">
        <Row>
          <Col md={6}>
            <p className="box-number text-start ">{item.name}</p>
          </Col>
          <Col md={6}>
            <p className="box-price text-end m-0">
              ${parseFloat(item.rate).toFixed(2)}(
              <span className="box-discount text-end">
                {item.discount}% Off {item.price}
              </span>
              )
            </p>
          </Col>
          <Col md={6}>
            <button className="location-button mb-3">
              <FaMapMarkerAlt /> {item.state}
            </button>
            <p className="box-patterns m-0">Pattern: {item.pattern}</p>
            <p className="activatetext">Activate in 7-10 days</p>
          </Col>
          <Col md={6} className="mt-auto">
            {item.stock > 0  ?  (
              <>
                <p className="statustext">{item.status}</p>
<CartButton
                  productId={item.id}
                  className="buybutton"
                ></CartButton>
                {item.rate > 5000 && (
                  <>
                    <p className="offertext">You can Make an Offer</p>
                  </>
                )}
              </>
            ): (
              <p className="sold-out-text text-end">Sold Out</p>
            ) }
          </Col>
          <Col md={6}></Col>
        </Row>
      </div>
    </Col>
  ));


  const handleSelectAreacode = (selectedAreacode) => {
    setSelectedAreacode(selectedAreacode);
    applyFilters(selectedState, selectedPattern, selectedAreacode);
    setActiveKey("2"); // You can set the active key accordingly
  };
  
  const handleSelectState = (selectedState) => {
    setSelectedState(selectedState);
    applyFilters(selectedState, selectedPattern, selectedAreacode);
    setActiveKey("0");
  };
  
  const handleSelectPattern = (selectedPattern) => {
    setSelectedPattern(selectedPattern);
    applyFilters(selectedState, selectedPattern, selectedAreacode);
    setActiveKey("1");
  };
  
  const sortedPhoneNumbers = [...phoneNumbers].sort((a, b) => {
    const priceA = a.rate;
    const priceB = b.rate;

    return sortBy === "lowToHigh" ? priceA - priceB : priceB - priceA;
  });
  return (
    <Container fluid>
      <Row className="mt-5">
        <Col md={4}></Col>
        <Col md={12} className="text-center">
          <h2>
          Phone Numbers for{" "}
          {selectedState && selectedPattern ? `${selectedState} & ${selectedPattern}` : ""}
          {selectedState && !selectedPattern ? `${selectedState}` : ""}
          {!selectedState && selectedPattern ? `${selectedPattern}` : ""}
          {!selectedState && !selectedPattern && "All States and Patterns"}
          </h2>
        </Col>
        <Col md={4}></Col>
      </Row>
      <Container className="pb-5">
        <Row className="mt-3">
          <Col md={4}>
          <FilterSidebar 
                applyFilters={applyFilters} 
                handleSelectState={handleSelectState}
                handleSelectPattern={handleSelectPattern}
                handleSelectAreacode={handleSelectAreacode} // Add this prop
                selectedState={selectedState}
                selectedPattern={selectedPattern}
                selectedAreacode={selectedAreacode} // Pass selectedAreacode as a prop
              />
          </Col>
          <Col className={8}>
            <Row>
              <Sorting setSortBy={setSortBy} />
              {sortedPhoneNumbers.map((item) => (
                <Col md={12} key={item.Id}>
                  <div className="box">
                    <Row>
                      <Col md={6}>
                        <p className="box-number text-start ">{item.name}</p>
                      </Col>
                      <Col md={6}>
                        <p className="box-price text-end m-0">
                          ${parseFloat(item.rate).toFixed(2)}(
                          <span className="box-discount text-end">
                            {item.discount}% Off 
                          </span>
                          )
                        </p>
                        <p className="box-price text-end m-0">
                          <span className="box-discount text-end">
                            Save $
                            {(
                              parseFloat(item.rate) - parseFloat(item.price)
                            ).toFixed(2)}
                          </span>
                        </p>
                      </Col>
                      <Col md={6}>
                        <button className="location-button mb-3">
                          <FaMapMarkerAlt /> {item.state}
                        </button>
                        <p className="box-patterns m-0">
                          Pattern: {item.pattern}
                        </p>
                        <p className="activatetext">Activate in 7-10 days</p>
                      </Col>
                      <Col md={6} className="mt-auto">
                        {item.stock > 0 ?  (
                          <>
                            <p className="statustext">{item.status}</p>
<CartButton
                              productId={item.id}
                              className="buybutton"
                            ></CartButton>
                            {item.rate > 5000 && (
                              <>
                                <p className="offertext">
                                  You can Make an Offer
                                </p>
                              </>
                            )}
                          </>
                        ) : (
                          <p className="sold-out-text text-end">Sold Out</p>
                        )}
                      </Col>
                      <Col md={6}></Col>
                    </Row>
                  </div>
                </Col>
              ))}

{!loading && (phoneNumbers.length === 0 || phoneNumbers === null) && (
  <p>No phone numbers available.</p>
)}

            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Showall;
