import React, { useState, useEffect } from "react";
import { Accordion, Nav } from "react-bootstrap";
import axios from "axios";
import "./SideNav.css";

const FilterSidebar = ({ applyFilters, selectedState, selectedPattern, selectAreaCode }) => {
  const [stateData, setStateData] = useState([]);
  const [patternData, setPatternData] = useState([]);
  const [selectState, setSelectState] = useState("");
  const [selectPattern, setSelectPattern] = useState("");
  const [selectedAreaCode, setSelectedAreaCode] = useState(""); // Added this line
  const [areaCodeData, setAreaCode] = useState([]);
  const [activeKey, setActiveKey] = useState(null);
  const [statesList, setStatesList] = useState([]);
  const [patternsList, setPatternsList] = useState([]);

  useEffect(() => {
    axios.get("https://vanityapi.adef.tech/Product/StateList").then((response) => {
      setStatesList(response.data);
    });

    axios.get("https://vanityapi.adef.tech/Home/patternDropdown").then((response) => {
      setPatternsList(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedState) {
      axios
        .get(`https://vanityapi.adef.tech/Product/AreacodeBasedonState?state=${selectedState}`)
        .then((response) => {
          debugger
          setAreaCode(response.data);
        });
    }
  }, [selectedState]);

  const handleSelectState = (stateName) => {
    setSelectState((prevState) => {
      setSelectedAreaCode("");
      debugger
      var areacode = "";
      applyFilters(stateName, selectedPattern, areacode);
      setActiveKey("0");
      return stateName;
    });
  };

  const handleSelectPattern = (patternId) => {
    setSelectPattern((prevPattern) => {
      applyFilters(selectedState, patternId, selectedAreaCode);
      debugger
      setActiveKey("1");
      return patternId;
    });
  };

  const handleSelectAreacode = (selectedAreacod) => {
    setSelectedAreaCode((prevAreacode) => {
      applyFilters(selectedState, selectedPattern, selectedAreacod);
      debugger
      setActiveKey("2");
      return selectedAreacod;
    });
  };

  const handleSelectAll = (type) => {
    if (type === "state" ) {
      setSelectState("");
      setSelectedAreaCode("");
      debugger
      if (selectedPattern == "") {
        window.location = '/filters';
        return; // Redirecting, so no need to proceed further
      } else {
        applyFilters("", selectedPattern, "" );
      }
      
      debugger
      setActiveKey("0");
    } else if (type === "pattern") {
      setSelectPattern("");
      if (selectedState == "") {
        window.location = '/filters';
        return; // Redirecting, so no need to proceed further
      }
      applyFilters(selectedState, "", selectedAreaCode );
      setActiveKey("1");
    } else if (type === "areacode") {
      setSelectedAreaCode("");
      applyFilters(selectedState, selectedPattern, "" );
      setActiveKey("2");
    }
  };

  return (
    <Nav className="flex-column mt-2">
      <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>

        
        {/* State Accordion */}
        <Accordion.Item eventKey="0">
          <Accordion.Header>Select State</Accordion.Header>
          <Accordion.Body className="accordion-body-scrollable">
            <Nav className="flex-column">
              <Nav.Link onClick={() => handleSelectAll("state")}>All States</Nav.Link>
              {statesList.map((item) => (
                <Nav.Link
                  key={item.Id}
                  onClick={() => handleSelectState(item.Statename)}
                  active={selectedState === item.Statename}
                >
                  {item.Statename}
                </Nav.Link>
              ))}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>

        {/* Industry Accordion */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>Select AreaCode</Accordion.Header>
          <Accordion.Body className="accordion-body-scrollable">
            <Nav className="flex-column">
              {areaCodeData.length > 0 ? (
                areaCodeData.map((item) => (
                  <Nav.Link
                    key={item}
                    onClick={() => handleSelectAreacode(item)}
                  >
                    {item}
                  </Nav.Link>
                ))
              ) : (
                <p>Select State</p>
              )}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>

        {/* Pattern Accordion */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Select Pattern</Accordion.Header>
          <Accordion.Body className="accordion-body-scrollable">
            <Nav className="flex-column">
              <Nav.Link onClick={() => handleSelectAll("pattern")}>
                All Patterns
              </Nav.Link>
              {patternsList.map((item) => (
                <Nav.Link
                  key={item.id}
                  onClick={() => handleSelectPattern(item.pattern1)}
                  active={selectedPattern === item.pattern1}
                >
                  {item.pattern1}
                </Nav.Link>
              ))}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
    </Nav>
  );
};

export default FilterSidebar;
