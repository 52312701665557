import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Shop from "./Components/Shop";
import Localnumber from "./Components/Localnumber";
import Onsale from "./Components/State";
import Statenumber from "./Components/Statenumber"; // Corrected capitalization
import Cart from "./Components/Cart";
import Login from "./Components/Login";
import Sign from "./Components/Sign";
import IndustryNumbers from "./Components/IndustryNumbers";
import Checkout from "./Components/Checkout";
import Pattern from "./Components/Pattern";
import PatternNumbers from "./Components/PatternNumbers";
// import Category from "./Components/Category";
import UserProfile from "./Components/UserProfile";
import InitiatePayment from "./Components/InitiatePayment";
import InvoiceComponent from "./Components/InvoiceComponent";
import PayPalComponent from "./Components/PayPalComponent";
import ForgotPassword from "./Components/ForgotPassword";
import "./logo.png";
import { TimerProvider } from "./Components/TimerContext";
import Aboutus from "./Components/Aboutus";
import Customnumbers from "./Components/Customnumbers";
import Demandandsupply from "./Components/Demandanddsupply";
import Digitalcoldstograge from "./Components/Digitalcoldstorage";
import FAQS from "./Components/FAQS";
import Porting from "./Components/Porting";
import Process from "./Components/Process";
import Allnumbers from "./Components/Allnumbers";
import Scrolltop from "./Components/Scrolltop";
import Whynumberkarma from "./Components/Whynumberkarma";
import VirtualPhoneNumbers from "./Components/VitualPhoneNumbers";
import Brokerage from "./Components/Brokerage";
import ResetPassword from "./Components/ResetPassword";
import OTP from "./Components/OTP";
import Local from "./Components/Local";
import VanityNumbers from "./Components/VanityNumbers";
import TollFreeNumbers from "./Components/TollFreeNumbers";
import TermsOfUse from "./Components/TermsOfUse";
import ProcessFAQ from "./Components/ProcessFAQ";
import TwoStep from "./Components/TwoStep";
import VipNumbers from "./Components/VipNumbers";
import Showall from "./Components/Showall";

function App() {
  // const [sessionExpired, setSessionExpired] = useState(false);
  // useEffect(() => {
  //   const sessionTimer = setTimeout(() => {
  //     setSessionExpired(true);
  //     handleSessionExpiration(); // Call the function to handle session expiration
  //   }, 5000);

  //   // Clear the timer when the component unmounts or when the user logs out
  //   return () => clearTimeout(sessionTimer);
  // }, []);

  // const cartDelete = (id) => {
  //   if (id) {
  //     const formData = { id: id };
  //     const data = new URLSearchParams(formData);

  //     fetch("https://vanityapi.adef.tech/Cart/Delete", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //       body: data,
  //     })
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! Status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         if (data.responseCode === 1) {
  //           const showSuccessToast = () => {

  //             toast.success("Cart item deleted.", {
  //               position: "top-right",
  //               autoClose: 3000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //             });
  //           };
  //           showSuccessToast();
  //           sessionStorage.removeItem("cartId");
  //           sessionStorage.removeItem("parkingplan");
  //           window.location = "/";
  //         } else {
  //           const showErrorToast = () => {
  //             toast.error(data.responsemessage, {
  //               position: "top-right",
  //               autoClose: 3000,
  //               hideProgressBar: false,
  //               closeOnClick: true,
  //               pauseOnHover: true,
  //               draggable: true,
  //             });
  //           };
  //           showErrorToast();
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("API Error:", error);
  //       });
  //   }
  // };

  // const handleSessionExpiration = () => {
  //   const cartid = sessionStorage.getItem("cartId");
  //     cartDelete(cartid);
  // };


  return (
    <div className="App">
      <TimerProvider>
        <BrowserRouter>
          <Header />
          <Scrolltop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/numberBank" element={<Shop />} />
            <Route path="/state" element={<Onsale />} />
            <Route path="/industries" element={<Localnumber />} />
            <Route path="/industry/:industryId" element={<IndustryNumbers />} />
            <Route path="/pattern" element={<Pattern />} />
            <Route
              path="/patternnumbers/:patternId"
              element={<PatternNumbers />}
            />
            <Route path="/test" element={<Home />} />
            <Route path="/profile" element={<UserProfile />} />
            <Route path="/statenumber/:stateName" element={<Statenumber />} />
            <Route path="/vanity" element={<Home />} />
            <Route path="/offer" element={<Home />} />
            <Route path="/services" element={<Home />} />
            <Route path="/blog" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/Sigin" element={<Sign />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/initiatepayment" element={<InitiatePayment />} />
            <Route path="/invoice" element={<InvoiceComponent />} />
            <Route path="/paypal" element={<PayPalComponent />} />
            <Route path="/aboutus" element={<Aboutus/>} />
            <Route path="/customnumbers" element={<Customnumbers/>} />
            <Route path="/demandandsupply" element={<Demandandsupply/>} />
            <Route path="/digitalcoldstorage" element={<Digitalcoldstograge/>} />
            <Route path="/faqs" element={<FAQS/>} />
            <Route path="/porting" element={<Porting/>} />
            <Route path="/process" element={<Process/>} />
            <Route path="/filters" element={<Allnumbers />} />
            <Route path="/whynumberkarma" element={<Whynumberkarma />} />
            <Route path="/virtualphonenumbers" element={<VirtualPhoneNumbers />} />
            <Route path="/brokerage" element={<Brokerage />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/otp" element={<OTP />} />
            <Route path="/local" element={<Local />} />
            <Route path="/VanityNumber" element={<VanityNumbers/>}/>
            <Route path="/tollfreenumbers" element={<TollFreeNumbers />} />
            
            <Route path="/termsofuse" element={<TermsOfUse />} />
            <Route path="/processfaq" element={<ProcessFAQ/>}/>
            <Route path="/twostepotp" element={<TwoStep />} />
            <Route path="/vipnumbers" element={<VipNumbers />} />  
            <Route path="/show/:stateName" component={Showall} />
            <Route path="/showPattern/:pattern" component={Showall} />
            <Route path="/show" element={<Showall />}></Route>
          </Routes>
          <Footer />
        </BrowserRouter>
      </TimerProvider>
    </div>
  );
}

export default App;
